<template>
    <div>
        <div class="background"></div>
        <Header class="cmcad-top" :navList="navList" @search="handleSearchEvent" :typeList="[]"
            :navCurrentId="navCurrentId" :logo="logoUrl" type="3"></Header>
        <!-- 面包屑 -->
        <div class="container mt15 flex x-left flex-wrap">
            <div class="color999">您所在的位置：</div>
            <a-breadcrumb>
                <a-breadcrumb-item>
                    <router-link class="color999" :to="{ path: '/' }">首页</router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>
                    <router-link class="color999" :to="{ path: '/journal-article' }">期刊论文</router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>
                    <a class="color999" @click="$router.back(-1)">摘要数据库</a>
                    <!-- <router-link class="color999" :to="{ path: '/database/index' }">摘要数据库</router-link> -->
                </a-breadcrumb-item>
                <a-breadcrumb-item>
                    <router-link class="color999" :to="{ path: '' }">论文详情</router-link>
                </a-breadcrumb-item>
            </a-breadcrumb>
        </div>
        <div class="content flex">
            <div class="guide-list-left">
                <a-spin :spinning="loading" tip="Loading...">
                    <!-- 文章详情 -->
                    <div class="guidelist-article mt10 t-l pb5">
                        <p class="fs22 strong color333 mb10">{{ infomation.title }}</p>
                    </div>
                    <div class="mt20 t-l" v-if="infomation.real_name || infomation.org_cnname">
                        <p class="strong mb10 cursor" v-if="infomation.real_name"
                            @click.stop="handleSearchEvent(infomation.real_name,'all_name')">【{{ infomation.real_name }}】</p>
                        <span class="strong" v-if="infomation.org_cnname">【{{ infomation.org_cnname }}】</span>
                    </div>
                    <div class="mt20">
                        <div class="t-l mb10 pore" v-if="infomation.img_path">
                            本论文被评为
                            <span class="strong">"电子壁报"</span>
                            <span v-if="(bibaoList[0] || {}).image_url" class="paper-btn ml10 color333 strong"
                                @click="handlePreviewEvent('bibaoList', 0)">
                                <img class="paper-img ml10 color333 strong" @load="handleLoadEvent($event, 0)"
                                    :src="bibaoList[0].image_url | urlFilter(750)" alt="" />
                                点击观看</span>
                        </div>
                        <div class="t-l mb10" v-if="infomation.video_id && infomation.video_id != 0">
                            本论文被评为
                            <span class="strong">"大会发言"</span>
                            <span class="paper-btn ml10 color333 strong"
                                @click="videoJumpDetail(infomation.video_id)">点击观看</span>
                        </div>
                        <div class="t-l" v-if="abstract.length" v-for="(item, index) in abstract" :key="index"
                            v-html="item" style="word-wrap: break-word;"></div>
                        <a-empty style="margin-top:100px;" v-if="!abstract.length && !loading" />
                    </div>
                    <div class="t-l fs14 mb10" v-if="infomation.keywords">
                        <span class="color999">关键词：</span>
                        <span class="strong" style="color:#004CA1;">{{ infomation.keywords }}</span>
                    </div>
                    <p class="fs14 color999 row-1 t-l">
                        <span class="mr20 cursor color333" v-if="infomation.cn_title"
                            @click.stop="handleSearchEvent(infomation.cn_title,'cn_title')"><span class="color999">来源：</span>{{
                                    infomation.cn_title
                            }}</span>
                    </p>
                    <img class="mt40" style="width:100%;" src="~@/assets/images/adv-banner1.png" alt="">
                </a-spin>


                <!--  在底部增加相关视频和相关会议，按照关键字词调用。  -->
                <div class="mt20 t-l" v-if="videoPagination.current != 1 || videoPlaybackList.length > 0">
                    <a-tabs default-active-key="1" :animated="false">
                        <a-tab-pane key="1" tab="相关视频">
                            <a-spin :spinning="loading" tip="Loading...">
                                <div style="min-height:200px;">
                                    <course-item width="246px" :lineCount="3" :data-list="videoPlaybackList" type="2" :targetType="1" @clickEvent="handleOtherVideoEvent"></course-item>
                                    <a-empty v-if="!videoPlaybackList.length && !loading" />
                                </div>
                            </a-spin>
                            <div class="t-c" v-if="videoPagination.total > 0">
                                <a-pagination :total="videoPagination.total" v-model:current="videoPagination.current" show-quick-jumper @change="handlePageChangeEvent" :hideOnSinglePage="true" />
                            </div>
                        </a-tab-pane>
                    </a-tabs>
                </div>
            </div>
            <div class="guide-list-right mt20">
                <!-- 相关文章、相关类别、相关壁报、相关视频 -->
                <layout-right :config="layoutRightConfig" @articleDetail="articleDetail"></layout-right>
            </div>
        </div>
        <photoswipe ref="photoswipe" :picturelist="picList"></photoswipe>
    </div>
</template>

<script>
import Header from '@/components/layout/layout-header';
import Footer from "@/components/layout/layout-footer";
import layoutRight from '@/components/layout/layout-right';
import commonTitle from '@/components/common-title';
import commentList from '@/components/comment-list.vue';
import acrossMediaItem from '@/components/across-media-item.vue';
import liveItem from '@/components/live-item.vue';
import verticalMediaItem from '@/components/vertical-media-item.vue';
import photoswipe from '@/components/photoswipe.vue';
import courseItem from '@/components/courser-list-item';
import { videoJumpDetail, articlePicJumpDetail } from '@/utils/jumpPageMethods'
import {jsonFormat} from "@/utils/jsonFormat";
export default {
    name: 'GuideListDetail',
    components: {
        Header, Footer, layoutRight, commonTitle, commentList, acrossMediaItem, liveItem, verticalMediaItem, photoswipe, courseItem
    },
    data() {
        return {
            layoutRightConfig: {
                // 本月会议
                meeting: {
                    show: true, // 是否显示
                    title: '相关会议', // 标题
                    methods: 'get',
                    data: {
                        year: new Date().getFullYear(),
                        month: new Date().getMonth() + 1,
                        page: 1,
                        pageSize: 2,
                        is_now_year_meeting: 1,
                    },   // 参数条件
                },
                // 推荐直播
                live: {
                    show: true, // 是否显示
                    methods: 'get',
                    title: '相关直播', // 标题
                    data: {
                        page: 1,
                        limit: 3
                    },   // 参数条件
                },
                // 推荐视频
                video: {
                    show: true, // 是否显示
                    methods: 'get',
                    title: '最新视频', // 标题
                    data: {
                        page: 1,
                        PageSize: 4
                    },   // 参数条件
                },
            },
            title: '',
            picList: [],
            bibaoList: [],
            id: this.$route.query.classId,
            meeting_id: this.$route.query.meeting_id,
            infomation: {},
            abstract: [],
            articleRelate: {},
            loading: true,
            logoUrl: 'images/logo.png',
            navList: [],//顶部菜单
            navCurrentId: '',//当前页面菜单对应下标
            videoPlaybackList: [], //相关视频
            authorMeetingList: [], //相关会议
            videoPagination: {
                current: 1,
                pageSize: 6,
                total: 0
            },
        }
    },
    created() {
        this.pageInit()
    },
    methods: {
        videoJumpDetail,
        articlePicJumpDetail,
        handleSearchEvent(search_name,type) {
            this.$router.push({
                path: '/database/index',
                query: {
                    search_name,
                    search_type: type || 'title'
                }
            })
        },
        getInfoDetail() {
            return this.request.post('GetAbstractDetail', { id: this.id })
        },
        pageInit() {
            this.loading = true
            let requestArr = [
                this.getInfoDetail(),
            ]
            Promise.all(requestArr).then(res => {
                // 论文详情
                let infomation = res[0].data[0]
                infomation.keywords = infomation.keywords.replace('关键词:', '')
                this.infomation = infomation
                // 壁报观看
                this.bibaoList = [{
                    image_url: this.infomation.img_path,
                    title: this.infomation.title
                }];
                let abstract = this.infomation.abstract
                let arr = []
                abstract.forEach(item => {
                    for (let key in item) {
                        if (key != 'abstract_id') {
                            arr.push(item[key])
                        }
                    }
                })
                this.abstract = arr

                this.loading = false;
                // 相关视频
                this.getRelatedVideo();
            })
        },
        articleDetail(id) {
            this.id = id
            this.meeting_id = this.meeting_id
            this.layoutRightConfig.relatedArticle.data.id = id
            this.pageInit()
        },
        // 读取图片高度
        handleLoadEvent(e, index) {
            this.$set(this.bibaoList[index], 'height', e.target.naturalHeight)
        },
        // 查看图片
        handlePreviewEvent(field, index) {
            this.picList = this[field];
            this.$nextTick(() => {
                this.$refs.photoswipe.showPhowoswipe(index, this.picList);
            })
        },

        // 底部tab切换
        handleTabChangeEvent(key) {
            console.log(key);
        },
        // 相关视频
        getRelatedVideo() {
            console.log(this.infomation.video_id);
            let data = {
                page : this.videoPagination.current,
                pageSize : this.videoPagination.pageSize,
                // 英文 就按照 ’, ‘分割再用|合并，中文用’  ‘分割再用|合并
                keyword : this.infomation.keywords.split('  ').join('|').split(', ').join('|').split('; ').join('|').split('；').join('|').split('、').join('|'),
            }


            this.request.get('GetRecommendVideos', data ).then(res => {
                let videoObj = {
                    img: "default_pic_url",
                }
                this.videoPagination.total = parseInt(res.data.count);
                if (res.data.list.length) {
                    let data = res.data.list;
                    this.videoPlaybackList = jsonFormat(data, videoObj)
                } else {
                    this.videoPlaybackList = []
                }
            })
        },

        // 相关视频切换页码
        handlePageChangeEvent(page) {
            this.videoPagination.current = page;
            this.getRelatedVideo();
        },

        handleOtherVideoEvent(item) {
            this.$router.push({
                path: '/video-list-detail?id=' + item.id
            })
        },
    }
}
</script>
<style lang="less" scoped>
.container {
    width: 1240px;
    margin: 0 auto;
    background: #ffffff;
    padding: 20px 20px 0;


}

.cmcad-top {

    /deep/.ant-input,
    /deep/.ant-btn {
        height: 38px !important;
    }

    /deep/.ant-input-suffix {
        right: -15px;
    }

    /deep/.ant-input-search {
        background: #ffffff;
        border-radius: 100px;
    }

    /deep/.ant-input,
    /deep/.ant-btn {
        height: 40px;
    }
}

.content {
    width: 1240px;
    margin: 0 auto;
    background: #ffffff;
    padding: 0 20px;
}

.background {
    width: 100%;
    height: 408px;
    background: linear-gradient(360deg, rgba(239, 235, 255, 0) 0%, #EEEAFF 100%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.guide-list-left {
    width: 770px;

    .cursor {
        cursor: pointer;
    }

    .guidelist-breadcrumb {
        width: 300px;
        height: 20px;

        .guide-ant-breadcrumb {
            float: left;
            color: #c1c1c1;
        }
    }

    .guidelist-article {
        width: 100%;
        border-bottom: 1px dashed #d8d8d8;
    }

    .paper-btn {
        color: #1e51c9;
        cursor: pointer;
        position: relative;
        display: inline-block;

        .paper-img {
            width: 100%;
            height: 100%;
            position: absolute;
            opacity: 0;
        }
    }
}

.guide-list-right {
    width: 398px;
}
@media screen and (max-width:768px){
    .cmcad-top {
        /deep/.search-header{
            height: 25px;
            right: 0;
            .ml10{
                width: 60px!important;
                height: 25px!important;
            }
        }
        /deep/.search{
            width: calc(100% - 100px)!important;
        }
        /deep/.advanced-search{
            color: #fff;
        }
        /deep/.ant-input,
        /deep/.ant-btn {
            height: 25px !important;
        }
    }
    .container{
        width: 100%;
        padding: 10px 10px 0;
    }
    .content{
        width: 100%;
        display: block;
        margin-top: -12px;
        padding: 10px;
        .guide-list-left{
            width: 100%;
        }
        .guide-list-right{
            width: 100%;
            margin-top: 50px;
        }
    }
}
</style>
